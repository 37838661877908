<template>
  <div class="container">
    <van-nav-bar
      title="业务选择"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <van-cell-group center :border="false">
      <van-cell title="服务类型" is-link @click="showServicePopup"></van-cell>

      <van-cell title="车辆服务" v-show="carSList.length > 0">
        <template #label>
          <div class="service-box">
            <div
              class="service-item"
              v-for="(item, index) in carSList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </template>
      </van-cell>

      <van-cell title="服务范围" is-link @click="showAreaPopup">
        <template #right-icon>
          <van-icon name="plus" />
        </template>
      </van-cell>
      <van-cell :border="false">
        <template #title>
          <div class="p-cell">
            <div v-for="(item, index) in addressList" :key="index">
              <van-tag
                color="#f3c302"
                class="space"
                plain
                round
                closeable
                size="medium"
                type="primary"
                @close="removeArea(item.value)"
                :item="item"
              >
                {{ item.value }}
              </van-tag>
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="button-container">
      <div class="button-box">
        <van-button
          round
          block
          type="info"
          color="#F3C301"
          v-if="order_service_status == 2"
        >
          <span>认证中</span></van-button
        >
        <van-button
          round
          block
          type="info"
          color="#F3C301"
          v-else
          @click="toSubmit"
        >
          <span>去认证</span></van-button
        >
      </div>
    </div>

    <!-- 区域选择弹出框--->
    <van-popup v-model="showArea" position="bottom">
      <van-area
        title="选择服务区域"
        :area-list="areaList"
        visible-item-count="4"
        @confirm="ensureArea"
        @cancel="closeAreaPopup"
      />
    </van-popup>
    <!-- 服务需求弹出框 -->
    <van-popup v-model="showService" position="bottom">
      <van-cell-group :border="true">
        <van-cell cneter>
          <template #title>
            <div class="p-header">
              <div class="cancel">取消</div>
              <div class="p-item-text">选择服务需求(可多选)</div>
              <div class="ensure" @click="closeServicePopup">确定</div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-tree-select
        :items="items"
        :main-active-index.sync="activeIndex"
        :active-id.sync="activeIds"
      />
    </van-popup>
  </div>
</template>

<script>
import AreaList from "@/utils/area";
import UserService from "@/api/user";
import ServiceCateService from "@/api/service";

export default {
  data() {
    return {
      area: "",
      order_service_status: null,
      serviceType: 1,
      showArea: false,
      showService: false,
      areaList: AreaList,
      code: "500112",
      activeIds: [],
      activeIndex: 0,
      addressList: [],
      serviceOptions: [],
      selectedCarList: [],
      carList: [],
      carSList: [],
      items: [
        
        {
          text: "企业服务",
          children: [
            {
              text: "注册新执照",
              id: 1,
            },
            {
              text: "变更执照",
              id: 2,
            },
            {
              text: "注销执照",
              id: 3,
            },
          ],
        },
        {
          text: "办证服务",
          children: [
            {
              text: "网约车从业资格证",
              id: 1,
            },
          ],
        },
        {
          text: "知识产权",
          children: [
            {
              text: "商标",
              id: 1,
            },
            {
              text: "软著",
              id: 2,
            },
          ],
        },
        {
          text: "装修用工",
          children: [
            {
              text: "电工",
              id: 1,
            },
            {
              text: "水工",
              id: 2,
            },
            {
              text: "木工",
              id: 3,
            },
            {
              text: "漆工",
              id: 4,
            },
            {
              text: "瓦工",
              id: 5,
            },
            {
              text: "石工",
              id: 6,
            },
          ],
        },
        {
          text: "会计服务",
          children: [
            {
              text: "报表咨询",
              id: 1,
            },
            {
              text: "代账",
              id: 2,
            },
          ],
        },
        
      ],
    };
  },
  async beforeMount() {
    const resp1 = await ServiceCateService.getCarServiceList(this, {});
    if (resp1.data.code == 10000) {
      console.log(resp1.data.data);
      var llist = resp1.data.data;
      var carList = new Array();
      var carSList = new Array();
      llist.forEach(function(item) {
        carList.push({
          id: item.id,
          text: item.zh_name,
        });
        carSList.push(item.zh_name);
      });
      this.carList = carList;
      this.carSList = carSList;
      this.items.unshift({
        text: "车辆服务",
        children: carList,
      });
    }

    //得到用户统计信息
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp2 = await UserService.staffInfo(this, params);
    console.log("AASDFAFA")
    if (resp2.data.code == 10000) {

      var options = resp2.data.data.order_service;
            console.log(options)
      this.order_service_status = resp2.data.data.order_service_status;
      if (options) {
        var list = options.split("|");
        var arr = this.serviceOptions;
        list.forEach(function(item) {
          if (item != "") {
            arr.push(parseInt(item));
          }
        });
        this.activeIds = arr; //选择的详细服务
        console.log(arr);
      }
      this.pArea = resp2.data.data.staff_add; //代办员区
      var address = resp2.data.data.order_round;
      if (address) {
        var list1 = address.split("|");
        var arr1 = this.addressList;
        list1.forEach(function(item) {
          if (item != "") {
            arr1.push({ value: item });
          }
        });
        this.addressList = arr1; //服务范围
      }
    }
  },
  methods: {
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    showAreaPopup: function() {
      this.showArea = true;
    },
    showServicePopup: function() {
      this.showService = true;
    },
    closeAreaPopup: function() {
      this.showArea = false;
    },
    async toSubmit() {
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      const params = {
        token: token,
      };

      const resp = await UserService.changStatus(this, params);
      console.log(resp);
      if (resp.data.code == 10000) {
        const resp2 = await UserService.staffInfo(this, params);
        if (resp2.data.code == 10000) {
          this.order_service_status = resp2.data.data.order_service_status;
        }
      }
    },
    closeServicePopup: function() {
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var list = this.activeIds;
      var serviceRound = "";
      var carS = new Array();
      var carList = this.carList;
      console.log(carList);
      list.forEach(function(item) {
        serviceRound = serviceRound + item + "|";
        carList.forEach(function(i) {
          if (i.id == item) {
            carS.push(i.text);
          }
        });
      });
      this.carSList = carS;
      console.log(carS);
      const params = {
        token: token,
        orderTyp: 1,
        orderService: serviceRound,
      };

      const resp = UserService.changOrderType(this, params);
      console.log(resp);
      this.showService = false;
    },
    removeArea: function(value) {
      var list = this.addressList.filter((item) => item.value != value);
      this.addressList = list;
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var list1 = this.addressList;
      var orderRound = "";
      list1.forEach(function(item) {
        orderRound = orderRound + item.value + "|";
        console.log(item.value);
      });
      const params = {
        token: token,
        orderRound: orderRound,
      };

      const resp = UserService.changOrderRound(this, params);
      console.log(resp);
    },
    ensureArea: function(val) {
      var data = val;
      var area = "";
      data.forEach((e) => {
        area = area + e.name + "-";
      });
      area = data[0].name + "-" + data[1].name + "-" + data[2].name;
      var flag = false;
      console.log(area);
      this.addressList.forEach(function(el) {
        if (el.value == area) {
          flag = true;
        }
      });
      if (!flag) {
        this.addressList.push({ value: area });
      }
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var list = this.addressList;
      var orderRound = "";
      list.forEach(function(item) {
        orderRound = orderRound + item.value + "|";
        console.log(item.value);
      });
      const params = {
        token: token,
        orderRound: orderRound,
      };

      const resp = UserService.changOrderRound(this, params);
      console.log(resp);
      this.closeAreaPopup();
    },
  },
};
</script>

<style scoped>
.service-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.service-box .service-item {
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 2px 6px;
  border-radius: 12px;
  border: solid 1px #f3c302;
}

.container {
  background-color: #ffffff;
}
.p-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
}

.p-cell {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.p-item-text {
  font-size: 14px;
  color: #333333;
}
.p-header .cancel {
  font-size: 14px;
  color: #969799;
}
.p-header .ensure {
  font-size: 14px;
  color: #f3c302;
}
.van-picker__confirm {
  color: #f3c302 !important;
  font-size: 14px !important;
}
.van-sidebar {
  width: 90px !important;
}
.van-sidebar-item--select::before {
  background-color: #f3c302 !important;
}
.van-sidebar-item--select {
  background-color: #ffffff !important;
}
.van-sidebar-item {
  padding: 12px 12px !important;
  background-color: #fafafa;
}
.van-tree-select__item--active {
  color: #f3c302 !important;
}
.van-tree-select__item {
  text-indent: 10px !important;
}
.van-picker-column__item {
  font-size: 14px !important;
}
.van-picker__title {
  font-size: 14px !important;
}
.van-picker__confirm {
  color: #f3c302 !important;
}
.space {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.button-container {
  position: fixed;
  bottom: 20px;
  width: 100%;
}
.button-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 40px 10px 40px;
}
</style>
